import { Organization } from 'pages/backoffice/types/organizations';
import { SeasonSummary } from 'pages/backoffice/types/seasons';

export enum CompetitionFormat {
  LEAGUE = 'league',
  CUP = 'cup',
}

export enum CompetitionGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum CompetitionRegion {
  DOMESTIC = 'Domestic',
  INTERNATIONAL = 'International',
}

export enum CompetitionTeamType {
  CLUB = 'club',
  NATIONAL = 'national_team',
}

export interface Competition {
  id: string;
  name: string;
  country: string | null;
  seasons: SeasonSummary[];
  logoUrl: string | null;
  format: CompetitionFormat | null;
  gender: CompetitionGender | null;
  tier: number | null;
  region: CompetitionRegion | null;
  teamType: CompetitionTeamType | null;
  organization: Omit<Organization, 'competitions'> | null;
}
